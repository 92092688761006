import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Seo from "../components/Seo"

export default function Contacts() {
  return (
    <React.Fragment>
      <Seo title={"Contacts"} />
      <Navbar />
      <main className="marginTopNavbar">
        <div className="main-container">
          <section className="overview">
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Contact Information</h1>
              </div>

              <div className="col-4">
                <p>
                  <span> Address</span> <br />
                  Parque Tecnológico de Cantanhede, Núcleo 04, Lote 3 3060-197
                  Cantanhede PORTUGAL
                </p>
                <br />
                <p>
                  <span> Phone</span> <br />
                  (+ 351) 231 410 890
                </p>
                <br />
                <p>
                  <span> Email</span> <br />
                  lino@uc-biotech.pt
                </p>
                <br />
              </div>
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="high-throughput-screening-facility" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Map & Directions</h1>
              </div>
              <div className="col-4"></div>

              <div className="col-4">
                <p>
                  <span> From Lisbon</span> <br />
                  A1 Lisboa-Porto – Exit A1 (Figueira da Foz A14) – A14 First
                  Exit (after 2,5 Km) to Cantanhede – EN 234 (10km through
                  Portunhos and Pena) – do not exit towards the Center of
                  Cantanhede, go straight ahead – go straight ahead at the 1st
                  roundabout towards Parque Tecnológico – go straight ahead at
                  the 2nd roundabout towards Parque Tecnológico – take 3rd exit
                  (left) at the 3rd roundabout and follow directions towards
                  Parque Tecnológico.
                </p>
                <br />
              </div>

              <div className="col-4">
                <p>
                  <span> From Oporto</span> <br />
                  A1 Lisboa-Porto – Exit A1 (Mealhada – EN234) – towards
                  Cantanhede EN 234 (10km through Murtede, Sete Fontes and
                  Ourentã) – take first exit (right) at the 1st roundabout
                  towards Parque Tecnológico – go straight ahead at the 2nd
                  roundabout towards Parque Tecnológico – take 3rd exit (left)
                  at the 3rd roundabout and follow directions towards Parque
                  Tecnológico.
                </p>
                <br />
              </div>
              <div className="col-4">
                <p>
                  <span> Coordinates</span> <br />
                  N 40,36059º or 40º, 21’, 38,10’’ <br />W 08,60690º or 08º,
                  36’, 24,85’’
                </p>
                <br />
              </div>
            </div>
          </section>

          <div className="col-8">
            <StaticImage
              draggable="false"
              className="image-border"
              src="../images/map.png"
              alt="Map"
              layout="fullWidth"
            />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}
